<template>
  <div class="map-wrapper">
    <ErrorAlert dismissible v-if="fetchError" @dismissed="load">
      <template #default>
        {{ fetchError }}
      </template>
      <template #dismiss>
        <BIconArrowRepeat title="Retry" />
      </template>
    </ErrorAlert>

    <PermissionsAlert class="m-3" v-if="permissionError" :message="permissionError" />

    <ErrorAlert dismissible v-if="error">
      <template #default>
        {{ error }}
      </template>
      <template #dismiss>
        <BIconXCircle title="Dismiss" />
      </template>
    </ErrorAlert>

    <ProtoMapController
      :can-access-playback="can_access_playback"
      :can-access-utilities="can_access_utilities"
      :parent-loading="loading > 0 || !processedMapToolData"
      :has-api-kills="true"
      :has-api-positions="true"
      :has-economy="true"
      :has-outcome="true"
      :has-plants="true"
      :has-reports="true"
      :has-wins="true"
      :create-bookmark-api="createMatchNote"
      :data="processedMapToolData"
      :map="mapData.id"
      :notes="notes"
      :note-tags="noteTags"
      :team="$route.params.team_id"
      :initial-state="initialState"
      @update:initialState="updateInitialState"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { BIconXCircle, BIconArrowRepeat } from 'bootstrap-vue'
import px from 'vue-types'
import { mapGetters } from 'vuex'

import InsufficientQuotaError from '@/api/InsufficientQuotaError.js'
import { createMatchNote, getNoteTags, getMatchNotes } from '@/api/notes.js'
import { createShortcut, getShortcut } from '@/api/shortcuts.js'
import axios from '@/axios.js'
import ErrorAlert from '@/components/generic/ErrorAlert.vue'
import PermissionsAlert from '@/components/generic/PermissionsAlert.vue'
import ProtoMapController from '@/components/Map/ProtoMapController.vue'
import { BOOKMARK_VERSION } from '@/constants'
import mapPage from '@/pages/mixins/mapPage'
import { prepareMatchData } from '@/utils/prepareMatchData.js'

import { getMatchInfo, getMatchRiotData } from '../api/matches/index.js'
import genRoundId from '../components/Map/utils/genRoundId.js'
import processMapToolData from '../utils/processMapToolData.js'

export default {
  name: 'Map',
  mixins: [mapPage],
  components: {
    ProtoMapController,
    ErrorAlert,
    BIconXCircle,
    PermissionsAlert,
    BIconArrowRepeat,
  },
  props: {
    isCollegiate: px.bool.def(false),
  },
  data: () => ({
    loading: 0,
    error: null,
    fetchDataError: null,
    mapData: null,
    matches: null,
    notes: [],
    noteTags: [],
    matchesData: {},
    permissionError: null,
    processedMapToolData: null,
  }),
  computed: {
    ...mapGetters({
      agentsList: 'static/agentsList',
      loaded: 'map/loaded',
      loading_progress: 'map/loading_progress',
      server_error: 'map/server_error',
      has_assets: 'static/has_assets',
      maps: 'static/maps',
      weapons: 'static/weapons',
      gearsList: 'static/gearsList',
      getMapById: 'static/getMapById',
      getMapByName: 'static/getMapByName',
      agentsById: 'static/agentsById',
      getGearById: 'static/getGearById',
      getWeaponById: 'static/getWeaponById',
      can_access_playback: 'auth/can_access_playback',
      can_access_utilities: 'auth/can_access_utilities',
      weaponsList: 'static/weaponsList',
    }),
    fetchError() {
      return this.server_error || this.fetchDataError
    },
  },
  watch: {
    $route: {
      handler() {
        this.load()
      },
      immediate: true,
    },
  },
  methods: {
    async fetch_data({ team_id, map_name, matches, matchesData }) {
      this.loading++
      try {
        this.mapData = this.getMapByName(map_name)

        this.processedMapToolData = {
          ...(await processMapToolData({
            staticData: {
              agentsData: this.agentsList,
              weaponsData: this.weaponsList,
              gearsData: this.gearsList,
            },
            matchesData: Object.values(matchesData),
            hasKillLocations: false,
            reportMissingLocations: false,
            mapId: this.mapData.id,
          })),
          matchesData,
        }

        const mapData = await this.$store.dispatch('map/fetch_data', {
          map_name,
          team_id,
          matches,
          isCollegiate: this.isCollegiate,
        })

        this.processedMapToolData = Object.freeze({
          ...mapData,
          matchesData,
        })

        // prepare Notes and tags
        try {
          this.noteTags = Object.freeze(await getNoteTags())
          this.notes = (
            await Promise.all(
              matches.map(async matchId => {
                const notes = await getMatchNotes({ matchId, isCollegiate: this.isCollegiate })
                return Promise.all(
                  notes
                    .filter(({ extra_data }) => extra_data?.shortcut && extra_data?.version === BOOKMARK_VERSION)
                    .map(async note =>
                      Object.freeze({
                        ...note,
                        round_id: genRoundId(matchId, note.round_num),
                        ...(await getShortcut(note.extra_data.shortcut)),
                        tags: note.tags.map(tagId => this.noteTags.find(tag => tagId === tag.id)),
                      })
                    )
                )
              })
            )
          ).flat()
        } catch (e) {
          console.error(axios.extractErrorMessage(e), e)
          Sentry.captureException(e)
        }
      } finally {
        this.loading--
      }
    },

    async load() {
      this.loading++
      this.fetchDataError = null
      try {
        if (this.$route.query.matches) {
          await this.load_matches({
            matches: this.$route.query.matches,
            map_name: this.$route.params.map_name,
            team_id: this.$route.params.team_id,
          })
          if (this.$route.query.round) {
            this.$nextTick(() => {
              this.currentTime = +this.$route.query.round_millis
            })
          }
        } else {
          throw new Error('missing report or matches')
        }
      } catch (error) {
        console.log('Error while loading', error)
        Sentry.captureException(error)
        this.fetchDataError = axios.extractErrorMessage(error)
      } finally {
        this.loading--
      }
    },

    async load_matches({ matches, map_name, team_id }) {
      this.loading++
      try {
        this.matches = Array.isArray(matches) ? matches : [matches]

        if (!this.has_assets) {
          await this.$store.dispatch('static/get_assets')
        }

        this.mapData = this.getMapByName(map_name)

        // Round selector is designed for single match from store
        // load the necessary data only when single match is selected
        if (this.matches.length === 1) {
          const matchId = this.matches[0]
          const info = await getMatchInfo({ matchId, isCollegiate: this.isCollegiate })
          this.matchesData[matchId] = prepareMatchData({ matchInfo: info, agents: this.agentsById })
          this.mapData = this.$store.getters['static/getMapById'](info.map)
          this.mapToolData = {
            ...(await processMapToolData({
              staticData: {
                agentsData: this.$store.getters['static/agentsList'],
                weaponsData: this.$store.getters['static/weaponsList'],
                gearsData: this.$store.getters['static/gearsList'],
              },
              matchesData: Object.values(this.matchesData),
              orbsData: {},
              roundAdvancedPositionsData: {},
              roundSmokesData: {},
              roundUtilitiesData: {},
              roundWallsData: {},
              roundUtilitiesUsageData: {},
              mapId: info.map,
            })),
            map: this.mapData,
          }

          const riotData = await getMatchRiotData({ matchId, isCollegiate: this.isCollegiate })
          this.matchesData[matchId] = prepareMatchData({ matchInfo: info, riotData, agents: this.agentsById })
          this.mapData = this.$store.getters['static/getMapById'](info.map)
          this.mapToolData = {
            ...(await processMapToolData({
              staticData: {
                agentsData: this.$store.getters['static/agentsList'],
                weaponsData: this.$store.getters['static/weaponsList'],
                gearsData: this.$store.getters['static/gearsList'],
              },
              matchesData: Object.values(this.matchesData),
              orbsData: {},
              roundAdvancedPositionsData: {},
              roundSmokesData: {},
              roundUtilitiesData: {},
              roundWallsData: {},
              roundUtilitiesUsageData: {},
              mapId: info.map,
            })),
            map: this.mapData,
          }
        }

        // if (this.loading) {
        //   this.loading++
        //   return
        // }

        await this.fetch_data({
          matches: this.matches,
          map_name,
          team_id,
          matchesData: this.matchesData,
        })
      } catch (error) {
        if (error instanceof InsufficientQuotaError) {
          this.permissionError = error.message
        } else {
          throw error
        }
      } finally {
        this.loading--
      }
    },

    async createMatchNote(bookmark) {
      const { match, text, tags, round_num, round_millis, ...extraState } = bookmark
      const shortcut = await createShortcut(extraState)

      const params = {
        matchId: match,
        text,
        tags,
        round_num,
        round_millis,
        extra_data: {
          shortcut: shortcut,
          version: BOOKMARK_VERSION,
        },
      }

      const newNote = Object.freeze({
        ...(await createMatchNote(params)),
        ...extraState,
        round_id: genRoundId(match, round_num),
        tags: tags.map(tagId => this.noteTags.find(tag => tagId === tag.id)),
      })

      this.notes.push(newNote)
      return newNote
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper {
  // position: fixed;
  // width: 100vw;
  // height: 100vh;
  position: relative;
  height: 100%;
  min-height: 0;

  // display: flex;
  // flex-direction: column;
  // align-items: stretch;

  .map-container {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    min-height: 0;
  }
}

.round-meta {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.loading {
  text-align: center;
  margin-top: 30vh;
}
</style>
