<template>
  <div class="right-side-tabs">
    <V3Button :active="value === 'info'" @click="$emit('update:value', 'info')">Round Info</V3Button>
    <V3Button :active="value === 'stats'" @click="$emit('update:value', 'stats')">Round Stats</V3Button>
    <V3Button :active="value === 'xvy'" @click="$emit('update:value', 'xvy')">Round XvY</V3Button>
  </div>
</template>

<script>
import V3Button from '../v3dafi/V3Button.vue'
export default {
  name: 'ProtoRightSideTabs',
  components: { V3Button },
  model: {
    prop: 'value',
    event: 'update:value',
  },
  props: {
    value: String,
  },
}
</script>

<style scoped lang="scss">
.right-side-tabs {
  display: flex;
  align-items: stretch;
  align-self: stretch;
  height: 2.25rem;
  flex: 0 0 2.25rem;

  & > * {
    flex: 1 1 auto;
  }

  button {
    background-color: #211f2b;
    color: #898795;
    font-size: 0.6875rem;

    &.active {
      filter: drop-shadow(7.726px -12.858px 8px rgb(11 16 20 / 64%));
      background-color: #2f2c3b;
      color: #e8e9ee;
    }
  }
}
</style>
