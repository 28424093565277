<template>
  <V3ScrollableContainer class="lsb-container" vertical>
    <template v-if="displayType === 'views'">
      <div class="left-side-tabs" v-if="mapMode === 'replay2d' && vodPlayer !== 'playback'">
        <V3BtnGroup>
          <V3Button :active="vodPlayer === vodPlatform" @click="setVODPlayer(vodPlatform)"> VOD </V3Button>
          <V3Button :active="vodPlayer === 'vod'" @click="setVODPlayer('vod')"> Minimap </V3Button>
        </V3BtnGroup>
      </div>

      <div class="left-side-tabs" v-else-if="mapMode === 'replay2d' && vodPlayer === 'playback'">
        <V3BtnGroup>
          <V3Button @click="setReplay2DMode('agents')" :active="mapSubMode === 'agents'"> Icons </V3Button>
          <V3Button @click="setReplay2DMode('traces')" :active="mapSubMode === 'traces'"> Traces </V3Button>
        </V3BtnGroup>
      </div>

      <div class="left-side-tabs" v-else-if="mapMode === 'analytics' && mapSubMode !== 'kdzones'">
        <V3BtnGroup>
          <V3Button @click="setAnalytics2DMode('heatmap')" :active="mapSubMode === 'heatmap'"> Heatmap </V3Button>
          <V3Button @click="setAnalytics2DMode('traces')" :active="mapSubMode === 'traces'"> Traces </V3Button>
          <V3Button @click="setAnalytics2DMode('off')" :active="mapSubMode === 'off'"> None </V3Button>
        </V3BtnGroup>
      </div>

      <div class="left-side-tabs" v-if="!(mapMode === 'replay2d' && vodPlayer !== 'playback')">
        <V3BtnGroup>
          <V3Button :active="mapOptions.includes('smokes')" @click="toggleMapOptions('smokes')"> Smokes </V3Button>
          <V3Button :active="mapOptions.includes('walls')" @click="toggleMapOptions('walls')"> Walls </V3Button>
          <V3Button
            :active="mapOptions.includes('deaths')"
            @click="toggleMapOptions('deaths')"
            v-if="mapSubMode !== 'kdzones'"
          >
            Deaths
          </V3Button>
        </V3BtnGroup>
      </div>

      <template v-if="mapMode === 'replay2d' && vodPlayer === 'playback'">
        <template v-if="mapSubMode === 'agents'">
          <slot name="snapshot" />
          <V3Panel expanded title="hide icons" style="width: 100%">
            <V3BtnGroup>
              <V3Button
                :active="this.state.iconHide.unselected"
                @click="() => (this.state.iconHide.unselected = !this.state.iconHide.unselected)"
              >
                Hide Unselected
              </V3Button>
            </V3BtnGroup>
          </V3Panel>
        </template>
        <template v-for="team in teams">
          <V3Panel
            :expandable="true"
            style="width: 100%"
            :expand-on-title-click="false"
            :expanded.sync="teamPanelExpanded[team.id]"
            :key="`panel-${team.id}`"
            @titleClick="togglePlaybackTeamPlayers(team)"
          >
            <template #header-text>
              <div class="mvo-team-name" :class="{ disabled: !team.enabled }">{{ team.name }}</div>
            </template>
            <template #header-icon>
              <V3TeamLogo :src="team.logo_url || team.image" grayscale :class="{ disabled: !team.enabled }" />
            </template>
            <template #default>
              <V3Container :visible="teamPanelExpanded[team.id]">
                <div :key="`agents-${team.id}`" class="agents-view-panel">
                  <AgentReplayDisplayOptions
                    v-for="teamAgent in team.agents"
                    :key="teamAgent.player.id"
                    :agent="teamAgent.agent"
                    :player="teamAgent.player"
                    :abilities="teamAgent.abilities"
                    :filters="filters"
                    :disabled="!team.enabled"
                  />
                </div>
              </V3Container>
            </template>
          </V3Panel>
        </template>
      </template>

      <template v-if="mapMode === 'analytics'">
        <KDZoneFilter v-if="mapSubMode === 'kdzones'" />

        <V3BtnGroup class="left-side-tabs">
          <V3Button
            v-if="mapSubMode === 'heatmap'"
            :active="this.state.minimapSelector"
            @click="() => (this.state.minimapSelector = !this.state.minimapSelector)"
          >
            Player Position %
          </V3Button>
        </V3BtnGroup>

        <template v-for="team in teams">
          <V3Panel
            style="width: 100%"
            :expandable="true"
            :expand-on-title-click="false"
            :expanded.sync="teamPanelExpanded[team.id]"
            :key="`panel-${team.id}`"
            @titleClick="toggleAnalyticsTeamPlayers(team)"
          >
            <template #header-text>
              <div class="mvo-team-name" :class="{ disabled: !team.enabled }">{{ team.name }}</div>
            </template>
            <template #header-icon>
              <V3TeamLogo :src="team.logo_url || team.image" grayscale :class="{ disabled: !team.enabled }" />
            </template>
            <template #default>
              <V3Container :visible="teamPanelExpanded[team.id]">
                <div :key="`agents-${team.id}`" class="agents-view-panel">
                  <AgentAnalyticsDisplayOptions
                    v-for="teamAgent in team.agents"
                    :key="teamAgent.player.id"
                    :agent="teamAgent.agent"
                    :player="teamAgent.player"
                    :abilities="teamAgent.abilities"
                    :filters="filters"
                    :disabled="!team.enabled"
                  />
                </div>
              </V3Container>
            </template>
          </V3Panel>
        </template>
      </template>
    </template>

    <slot />
  </V3ScrollableContainer>
</template>

<script>
import AgentAnalyticsDisplayOptions from '@/components/Map/components/AgentAnalyticsDisplayOptions.vue'
import AgentReplayDisplayOptions from '@/components/Map/components/AgentReplayDisplayOptions.vue'
import V3BtnGroup from '@/components/Map/components/v3dafi/V3BtnGroup.vue'
import V3Button from '@/components/Map/components/v3dafi/V3Button.vue'
import V3Container from '@/components/Map/components/v3dafi/V3Container.vue'
import exposedDataState from '@/components/Map/mixins/exposedDataState'
import genAbilityHashId from '@/components/Map/utils/genAbilityHashId.js'
import genGid from '@/components/Map/utils/genGid'

import stringCompare from '../../../../utils/stringCompare.js'
import KDZoneFilter from '../KDZoneFilter.vue'
import V3Panel from '../v3dafi/V3Panel.vue'
import V3ScrollableContainer from '../v3dafi/V3ScrollableContainer.vue'
import V3TeamLogo from '../v3dafi/V3TeamLogo.vue'

export default {
  name: 'ProtoLeftSidebar',
  mixins: [exposedDataState],
  components: {
    V3Container,
    V3ScrollableContainer,
    V3Button,
    V3BtnGroup,
    V3Panel,
    V3TeamLogo,
    AgentReplayDisplayOptions,
    AgentAnalyticsDisplayOptions,
    KDZoneFilter,
  },
  props: {
    vodPlatform: String,
    displayType: String,
    analyticsSubMode: String,
    vodSubMode: String,
    replaySubMode: String,
  },
  data() {
    return {
      bookmarksExpanded: true,
      creatingBookmark: false,
      viewsExpanded: true,
      matchesExpanded: true,
      expanded: true,
      teamPanelExpanded: {},
    }
  },
  mounted() {
    this.teamPanelExpanded = Object.fromEntries(this.teams.map(team => [team.id, team.enabled]))
  },
  computed: {
    activeAnalyticsTeams() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [
            team.id,
            this.filters.display.players &&
              Object.keys(this.teamPlayerIds[team.id]).some(id => this.filters.display.players?.[id]),
          ])
        )
      )
    },
    activePlaybackTeams() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [
            team.id,
            this.filters.display.gids &&
              Object.keys(this.teamGids[team.id]).some(gid => this.filters.display.gids[gid]),
          ])
        )
      )
    },
    filters: {
      get() {
        return this.state.filters
      },
      set(val) {
        this.state.filters = val
      },
    },
    teams() {
      return Object.values(this.data.teams)
        .map(team => ({
          ...team,
          agents: this.calcTeamAgents(team),
          enabled:
            this.state.filters.base.matches.length > 0
              ? Object.entries(this.data.matchTeams).some(([, matchTeam]) => {
                  if (matchTeam.team_id === team.id && this.state.filters.base.matches.includes(matchTeam.match_id)) {
                    return true
                  }
                  return false
                })
              : true,
        }))
        .sort(
          stringCompare(team => {
            return `${this.state.selected.team === team.id ? 1 : 0}-${team.name}`
          })
        )
    },
    teamGids() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [
            team.id,
            team.agents.reduce((acc, agent) => {
              acc[agent.agent.gid] = true
              return Object.values(agent.abilities).reduce((acc, ability) => {
                acc[ability.gid] = true
                return acc
              }, acc)
            }, {}),
          ])
        )
      )
    },
    teamPlayerIds() {
      return Object.freeze(
        Object.fromEntries(
          this.teams.map(team => [team.id, Object.fromEntries(team.agents.map(({ player: { id } }) => [id, true]))])
        )
      )
    },
    vodPlayer: {
      get() {
        return this.state.vodPlayer
      },
      set(value) {
        this.state.vodPlayer = value
      },
    },
    mapSubMode: {
      get() {
        return this.state.mapSubMode
      },
      set(val) {
        this.state.mapSubMode = val
      },
    },
    mapOptions: {
      get() {
        return this.state.mapOptions
      },
      set(val) {
        this.state.mapOptions = Object.freeze(val)
      },
    },
  },
  methods: {
    calcTeamAgents(team) {
      return Object.values(this.data.teamCompositions)
        ?.find(teamComposition => teamComposition.team_id === team.id)
        ?.composition?.map(composition => {
          if (!this.data.agents[composition.agent_id]) {
            console.log('Missing agent', composition, team)
          }
          return {
            agent: {
              ...this.data.agents[composition.agent_id],
              gid: genGid(
                'pos',
                team.id,
                this.data.teamPlayers[composition.team_player_id].player_id,
                composition.agent_id
              ),
            },
            player: this.data.players[this.data.teamPlayers[composition.team_player_id].player_id],
            abilities: Object.values(this.data.agents[composition.agent_id].abilities)
              .filter(
                ability =>
                  ability.slot.toLowerCase() !== 'passive' ||
                  composition.agent_id === '41fb69c1-4189-7b37-f117-bcaf1e96f1bf'
              )
              .reduce((acc, ability) => {
                acc[ability.slot] = {
                  ...ability,
                  gid: genGid(
                    'util',
                    team.id,
                    this.data.teamPlayers[composition.team_player_id].player_id,
                    composition.agent_id,
                    ability.slot
                  ),
                  abilityHashId: genAbilityHashId(this.data.agents[composition.agent_id], ability.slot),
                }
                return acc
              }, {}),
          }
        })
        .sort(stringCompare(({ agent }) => agent.name))
    },
    setReplay2DMode(mode) {
      this.state.mapSubMode = mode
      this.$emit('update:replaySubMode', mode)
    },
    setVODPlayer(mode) {
      this.state.vodPlayer = mode
      this.$emit('update:vodSubMode', mode)
    },
    setAnalytics2DMode(mode) {
      this.state.mapSubMode = mode
      this.$emit('update:analyticsSubMode', mode)
    },
    toggleMapOptions(option) {
      if ((this.mapOptions || []).includes(option)) {
        this.mapOptions = this.mapOptions.filter(o => o !== option)
      } else {
        this.mapOptions = [...(this.mapOptions || []), option]
      }
    },
    togglePlaybackTeamPlayers(team) {
      if (!team.enabled) {
        return
      }
      if (!this.teamPanelExpanded[team.id]) {
        this.teamPanelExpanded[team.id] = true
        return
      }
      const teamGids = this.teamGids[team.id]
      const otherGids = Object.fromEntries(
        Object.entries(this.filters.display.gids || {}).filter(([gid, enabled]) => enabled && !teamGids[gid])
      )
      if (this.activePlaybackTeams[team.id]) {
        this.filters.display.gids = Object.entries(otherGids).length === 0 ? null : otherGids
      } else {
        this.filters.display.gids = { ...otherGids, ...teamGids }
      }
    },
    toggleAnalyticsTeamPlayers(team) {
      if (!team.enabled) {
        return
      }
      if (!this.teamPanelExpanded[team.id]) {
        this.teamPanelExpanded[team.id] = true
        return
      }
      const playerIds = this.teamPlayerIds[team.id]
      const otherPlayerIds = Object.fromEntries(
        Object.entries(this.filters.display.players || {}).filter(([id, enabled]) => enabled && !playerIds[id])
      )
      if (this.activeAnalyticsTeams[team.id]) {
        this.filters.display.players = Object.keys(otherPlayerIds).length > 0 ? otherPlayerIds : null
      } else {
        this.filters.display.players = {
          ...otherPlayerIds,
          ...playerIds,
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.lsb {
  &-container {
    flex: 1 1 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
    padding: 0.25rem;
    background-color: #211f2b;
    width: 256px;
    align-content: center;
    align-items: center;
  }
}

.left-side-tabs {
  display: flex;
  width: fit-content;
  overflow: hidden;
  justify-content: space-evenly;
  background-color: #131319;
  flex-shrink: 0;

  .v3-button {
    flex: 1 1 auto;
    flex-flow: column nowrap;
    font-size: 0.625rem;
    text-transform: uppercase;
    color: #e8e9ee;
    gap: 0.5rem;
    padding: 0.375rem 0.75rem 0.5rem;

    svg {
      font-size: 1.125rem;
    }

    &:not(.active) {
      color: #7a7493;

      .lst-label {
        display: none;
      }
    }

    &.active {
      background-color: #23212e;

      svg {
        color: #e03a47;
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}

.mvo {
  &-team {
    &-container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      padding: 0;
      color: inherit;
    }

    &-name {
      font-size: 11px;
      text-transform: uppercase;
    }
  }

  &-player {
    flex: 1 1 auto;
    padding: 0;
    flex-flow: column nowrap;
    min-width: 0;
    gap: 0.25rem;

    &-container {
      display: flex;
      flex-flow: row nowrap;
      max-width: 100%;
      overflow: hidden;
      gap: 0.25rem;
      padding: 0.25rem;
    }

    &-agent {
      height: 1rem;
    }

    &-name {
      justify-self: stretch;
      max-width: 100%;
      font-size: 10px;
      color: #a4a2ad;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.agents-view-panel {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;
}
</style>
